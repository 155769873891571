import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import chargifyUI from "../images/chargify-ui.png";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />

      <section className="container mx-auto md:flex md:justify-between md:items-center p-6 min-h-full">
        <div className="md:flex-1">
          
          <h1 className="font-heading antialiased uppercase text-white text-6xl leading-none mb-6">
            <span className="clearfix font-heading antialiased text-xl mb-1 uppercase text-white">
              Hello! My name is
            </span>
            <span className="clearfix">
              Bob Orchard <span className="font-accent text-accent-100 antialiased">&</span>
            </span>
            <span>I Design Experiences</span>
          </h1>

          <p className="antialiased leading-snug text-white font-medium text-2xl mb-6 pr-20">
            I help funded startups and established businesses improve the user experience and design of their SaaS products
          </p>

          <p className="leading-loose text-white pr-20">
            As a product designer turned product manager, I use my background in marketing, business, design and product management to create deeply engaging product experiences that put the user first.
          </p>

          <p className ="mt-10 font-accent text-sm text-white">
            <a href="/work" className="bg-green-800 text-white py-4 px-8 rounded-full mr-2">view my work</a> or 
            <a href="" className="underline ml-2 italic">get in touch</a>
          </p>
        </div>
        <div className="md:flex-1 overflow-x-hidden -mr-6">
          <img
            alt="Screenshot of a single invoice view on Chargify"
            className="block rounded shadow"
            src={chargifyUI}
          />
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
